import Swiper from "swiper/core";
import { Autoplay, Pagination } from "swiper/modules";

import "./swiper.sass";

let swipers = [];

function initSwipers() {
  const swiperEls = document.querySelectorAll(".swiper");

  const currentWidth = window.innerWidth;

  return [...swiperEls].map((el) => {
    const isMobileSwiper = el.matches(".mobile-only");

    if (isMobileSwiper && currentWidth >= 768) return;

    const existingSwiper = swipers.find((swiper) => swiper?.el === el);

    if (existingSwiper) return existingSwiper;

    return new Swiper(el, {
      modules: [Autoplay, Pagination],
      loop: true,
      speed: 500,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
      },
      spaceBetween: 50,
    });
  });
}

document.addEventListener("DOMContentLoaded", () => {
  swipers = initSwipers();
});

function toggleSwipers() {
  if (!swipers.length) return;

  const currentWidth = window.innerWidth;

  if (currentWidth >= 768) {
    swipers.forEach((swiper) => {
      if (!swiper || swiper.destroyed) return;

      const isMobileSwiper = swiper.el.matches(".mobile-only");

      if (!isMobileSwiper) return;

      swiper.destroy();
    });
  } else {
    swipers = initSwipers();
  }
}

let resizeTimeout;

// debounced resize handler
window.addEventListener(
  "resize",
  (event) => {
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(() => {
        resizeTimeout = null;

        toggleSwipers();
      }, 100);
    }
  },
  false
);
